/*------- Home Common -------*/
.landing {
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.1;
    color: inherit;
  }
  .h1,
  h1 {
    font-size: 2rem;
  }
  .h2,
  h2 {
    font-size: 1.75rem;
  }
  .h3,
  h3 {
    font-size: 1.5rem;
  }
  .h4,
  h4 {
    font-size: 1.25rem;
  }
  .h5,
  h5 {
    font-size: 1rem;
  }
  .h6,
  h6 {
    font-size: 0.875rem;
  }
  .container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 15px;
  }
  .section-header h2 {
    font-size: 32px;
    font-weight: 700;
    margin: 0 0 16px;
  }
  .mat-card {
    .mat-divider {
      position: relative;
    }
  }
}
@-webkit-keyframes slideDown {
  0% {
    top: -60px;
  }
  100% {
    top: 0px;
  }
}
@keyframes slideDown {
  0% {
    top: -60px;
  }
  100% {
    top: 0px;
  }
}
.section-padding {
  padding: 40px 0;
}

.home-section {
  padding: 40px 0;
  .ngucarousel {
    overflow: visible !important;
    .ngu-touch-container {
      padding: 1.25rem 0;
      overflow: hidden;
    }
  }
}
.home-section mat-card:hover {
  box-shadow: 0 10px 16px rgba(0, 0, 0, 0.2) !important;
}

.section-header {
  margin: 0 0 2rem;
}

.section-header h2 {
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 16px;
}
.section-header p {
  font-size: 16px;
  max-width: 55rem;
  margin: 0;
}

.home-section-action {
  padding: 2.5rem 0 0;
}

[mat-card-icon] {
  font-size: 64px !important;
  height: 64px !important;
  width: 64px !important;
  transition: all 0.3s ease;
}
.home-fancy-card {
  border-top: 2px solid #212121;
  transition: all 0.3s ease;
  height: calc(100% - 35px);
  [fxlayout="column"] {
    height: 100%;
  }
}

.home-fancy-card:hover {
  border-top: 2px solid #3f51b5;
}
.home-fancy-card:hover [mat-card-icon] {
  color: #3f51b5;
}
.home-fancy-card .description {
  font-size: 15px;
  color: #616161;
}

.rounded-icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 64px;
  border: 2px solid;
  &.primary {
    color: #3f51b5;
    border-color: #3f51b5;
    background-color: rgba(#3f51b5, 0.2);
  }
  &.accent {
    color: #ffc107;
    border-color: #ffc107;
    background-color: rgba(#ffc107, 0.2);
  }
  &.warn {
    color: #f44336;
    border-color: #f44336;
    background-color: rgba(#f44336, 0.2);
  }
}

.subscribe-input {
  display: inline-flex;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12);
  padding: 0.25rem 0.25rem 0.25rem 1rem;
  border-radius: 2rem;
  overflow: hidden;
  background: #fff;
  min-width: 480px;
  .email-input {
    border: 0;
    font-size: 1rem;
    flex-grow: 1;
    padding: 0;
    line-height: 1;
    margin: 0 0;
    background: inherit;
    &:focus {
      outline: 0;
    }
  }
  button {
    border-radius: 2rem;
    font-weight: 500 !important;
    line-height: 48px;
    padding: 0 24px;
    .material-icons {
      margin-right: .5rem;
    }
  }

}
