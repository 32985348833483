@use '@angular/material' as mat;
@use 'sass:map';
@import './_egret.theme';

/* Include the core styles */
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

/* Create style without colors */
@include mat.all-component-themes(
  (
    color: null,
    density: -2,
    typography:
      mat.m2-define-typography-config(),
  )
);

/* Generate Primary, Accent and Warn palettes */
$default-primary-palette: (
  main: #0081ff,
  lighter: #b3d9ff,
  darker: #0064ff,
  200: #0081ff,
  contrast: (
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  ),
);
$default-theme-primary: mat.m2-define-palette($default-primary-palette, main, lighter, darker);

$default-accent-palette: (
  main: #ff8a48,
  lighter: #ffdcc8,
  darker: #ff6d30,
  200: #ff8a48,
  contrast: (
    main: rgba(black, 0.87),
    lighter: rgba(black, 0.87),
    darker: rgba(black, 0.87),
  ),
);
$default-theme-accent: mat.m2-define-palette($default-accent-palette, main, lighter, darker);

$default-warn-palette: (
  main: #ff3d57,
  lighter: #ffc5cd,
  darker: #ff273c,
  200: #ff3d57,
  contrast: (
    main: white,
    lighter: rgba(black, 0.87),
    darker: white,
  ),
);
$default-theme-warn: mat.m2-define-palette($default-warn-palette, main, lighter, darker);

/* Create Light theme. */
.egret-navy {
  // Foreground colors
  $dark-text: #000000;
  $dark-accent-text: rgba(rgba(black, 0.87), 0.54);
  $dark-disabled-text: rgba(rgba(black, 0.87), 0.38);
  $dark-dividers: rgba(rgba(black, 0.87), 0.12);
  $dark-focused: rgba(rgba(black, 0.87), 0.12);
  // Background colors
  $light-background: #fafafa;
  $light-bg-darker-5: darken($light-background, 5%);
  $light-bg-darker-10: darken($light-background, 10%);
  $light-bg-darker-20: darken($light-background, 20%);
  $light-bg-darker-30: darken($light-background, 30%);
  $light-bg-lighter-5: lighten($light-background, 5%);
  $dark-bg-tooltip: lighten(#2c2c2c, 20%);
  $dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
  $dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

  $light-theme: (
    color: (
      primary: $default-theme-primary,
      accent: $default-theme-accent,
      warn: $default-theme-warn,
      is-dark: false,
      foreground: (
        base: black,
        divider: rgba(black, 0.12),
        dividers: rgba(black, 0.12),
        disabled: rgba(black, 0.38),
        disabled-button: rgba($dark-text, 0.26),
        disabled-text: rgba(black, 0.38),
        elevation: black,
        secondary-text: $dark-accent-text,
        hint-text: rgba(black, 0.38),
        accent-text: $dark-accent-text,
        icon: $dark-accent-text,
        icons: $dark-accent-text,
        text: rgba(black, 0.87),
        slider-min: rgba(black, 0.87),
        slider-off: rgba($dark-text, 0.26),
        slider-off-active: rgba(black, 0.38),
      ),
      background: (
        background: $light-background,
        status-bar: $light-bg-darker-20,
        app-bar: $light-bg-darker-5,
        hover: $dark-bg-alpha-4,
        card: $light-bg-lighter-5,
        dialog: $light-bg-lighter-5,
        tooltip: $dark-bg-tooltip,
        disabled-button: $dark-bg-alpha-12,
        raised-button: $light-bg-lighter-5,
        focused-button: rgba(black, 0.12),
        selected-button: $light-bg-darker-20,
        selected-disabled-button: $light-bg-darker-30,
        disabled-button-toggle: $light-bg-darker-10,
        unselected-chip: $light-bg-darker-10,
        disabled-list-option: $light-bg-darker-10,
      ),
    ),
  );

  @include mat.all-component-colors($light-theme);
  @include egret-theme($light-theme);
}

/* Create Dark theme */
.egret-navy-dark {
  // Foreground Elements
  $light-text: #ffffff;
  $light-accent-text: rgba(white, 0.7);

  // Background config
  $dark-background: #1a2038;
  $dark-bg-lighter-5: lighten($dark-background, 5%);
  $dark-bg-lighter-10: lighten($dark-background, 10%);
  $dark-bg-lighter-20: lighten($dark-background, 20%);
  $dark-bg-lighter-30: lighten($dark-background, 30%);
  $light-bg-alpha-4: rgba(#fafafa, 0.04);
  $light-bg-alpha-12: rgba(#fafafa, 0.12);

  $dark-theme: (
    color: (
      primary: $default-theme-primary,
      accent: $default-theme-accent,
      warn: $default-theme-warn,
      is-dark: true,
      foreground: (
        base: $light-text,
        divider: rgba(white, 0.12),
        dividers: rgba(white, 0.12),
        disabled: rgba(white, 0.5),
        disabled-button: rgba($light-text, 0.3),
        disabled-text: rgba(white, 0.5),
        elevation: black,
        hint-text: rgba(white, 0.5),
        secondary-text: $light-accent-text,
        accent-text: $light-accent-text,
        icon: $light-text,
        icons: $light-text,
        text: $light-text,
        slider-min: $light-text,
        slider-off: rgba($light-text, 0.3),
        slider-off-active: rgba($light-text, 0.3),
      ),
      background: (
        background: $dark-background,
        status-bar: $dark-bg-lighter-20,
        app-bar: $dark-bg-lighter-5,
        hover: $light-bg-alpha-4,
        card: $dark-bg-lighter-5,
        dialog: $dark-bg-lighter-5,
        tooltip: $dark-bg-lighter-20,
        disabled-button: $light-bg-alpha-12,
        raised-button: $dark-bg-lighter-5,
        focused-button: rgba(white, 0.12),
        selected-button: $dark-bg-lighter-20,
        selected-disabled-button: $dark-bg-lighter-30,
        disabled-button-toggle: $dark-bg-lighter-10,
        unselected-chip: $dark-bg-lighter-20,
        disabled-list-option: $dark-bg-lighter-10,
      ),
    ),
  );

  @include mat.all-component-colors($dark-theme);
  @include egret-theme($dark-theme);

}
